table .table-tag{
    color: #233076;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.address h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 22px;
    margin-top: 0;
}
.address p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 15px;
}
.address .amount {
    font-weight: 600;
    font-size: 42px;
}
.table_footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
.footer-lable label {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 22px;
    margin-top: 0;
}
.footer-lable p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 22px;
    margin-top: 0;
}
.red{
    color: #E70000 !important;
}
.green{
    color: #05B49D !important;
}