.home_icon_img li a svg {
  position: relative;
}
.home_icon_img li a {
  border: none;
  background: none;
}
.dev {
  padding-left: 25px;
}
/* .border-end .navbar_sidebar.active{
    background: linear-gradient(91.53deg, #233076 13.86%, #41C7ED 112.43%) !important;
    color: #fff;
    border-radius: 28px 0px 0px 25px;
} */
.border-end .navbar_sidebar:hover {
  color: #fff !important;
}
.route-dom .list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #fff !important;
  background-color: #e5e5e5;
}
.home_icon_img .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background: linear-gradient(
  91.53deg,
  #233076 13.86%,
  #41c7ed 112.43%
) !important;
  border-radius: 28px 0px 0px 25px;
  box-shadow: 0px 4px 10px rgba(25, 97, 156, 0.2);
}
.home_icon_img .list-group-item-action.active:focus {
  background-color: linear-gradient(
    91.53deg,
    #233076 13.86%,
    #41c7ed 112.43%
  ) !important;
}
