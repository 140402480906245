.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
  
    /* Set vertical boundaries for the document editor. */
    max-height: 700px;
  
    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
  }
  .document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;
  
    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );
  
    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }
  
  /* Adjust the look of the toolbar inside the container. */
  .document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
  }
  /* Make the editable container look like the inside of a native word processor application. */
  .document-editor__editable-container {
    padding: calc( 2 * var(--ck-spacing-large) );
    background: var(--ck-color-base-foreground);
  
    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll;
  }
  
  .document-editor__editable-container .ck-editor__editable {
    /* Set the dimensions of the "page". */
    width: 816px;
    min-height: 1056px;
  
    /* Keep the "page" off the boundaries of the container. */
    /* padding: 1cm 2cm 2cm; */
  
    border: 1px hsl( 0,0%,82.7% ) solid;
    border-radius: var(--ck-border-radius);
    background: white;
  
    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );
  
    /* Center the "page". */
    margin: 0 auto;
  }


  .ck-editor__editable_inline {
    padding: 1cm 2cm 2cm !important;
  }

  /* Set the default font for the "page" of the content. */
  .document-editor .ck-content,
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
      font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  /* Adjust the headings dropdown to host some larger heading styles. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
    min-width: 6em;
  }
  
  /* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
  }
  
  /* Set the styles for "Heading 1". */
  .document-editor .ck-content h2,
  .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 2.18em;
    font-weight: normal;
  }
  
  .document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em;
  }
  
  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3,
  .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 1.75em;
    font-weight: normal;
    color: hsl( 203, 100%, 50% );
  }
  
  .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
  }
  
  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em;
  }
  
  /* Set the styles for "Heading 3". */
  .document-editor .ck-content h4,
  .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
  }
  
  .document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em;
  }
  
  /* Set the styles for "Paragraph". */
  .document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em;
  }
  /* Make the block quoted text serif with some additional spacing. */
  .document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc( 2 * var(--ck-spacing-large) );
    margin-right: calc( 2 * var(--ck-spacing-large) );
  }
  
  
  .report_p1{
    font-family: Calibri Light;
    /* line-height: 1px; */
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 12pt;
  }
  
  .report_h1 {
    font-size: 16pt !important;
    color: #2e74b5;
    font-family: Calibri Light;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  
  
  
  .report_h2{
    font-size: 14pt;
    color: #2e74b5;
    font-family: Calibri Light;
    text-decoration: underline;
    font-weight: bold;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  
  .report_h3{
    font-size: 14pt;
    color: #2e74b5;
    font-family: Calibri Light;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  
  
  .heading_heading1{
    font-size: 16pt;
    color: #2e74b5;
    font-family: Calibri Light;
  }
  
  .ck-heading_heading2 {
    font-size: 14pt;
    color: #2e74b5;
    font-family: Calibri Light;
    text-decoration: underline;
    font-weight: bold;
  }
  .ck-heading_heading3 {
    font-size: 14pt;
    color: #2e74b5;
    font-family: Calibri Light;
  }
  
  hr{
    margin: 0 !important;
    height: 2px !important;
    background: black !important;
  }
  
  p {
    margin: 0px !important;
    padding: 0px !important;
  }

  .ck-toolbar__items {
    justify-content: center;
  }

  /* .document-editor__editable-container {
    display: flex !important;
    justify-content: center !important;
  } */

  /* .ck-content {
    margin-top: 55px !important;
  } */
  .ck-toolbar {
    position: absolute !important;
    margin-top: -26px !important;
    z-index: 99;
  }

  /* @media only screen and (max-width: 1900px) {
    .ck-content {
        margin-top: 85px !important;
      }
  } */