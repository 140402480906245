.home_drop {
  margin-top: -7px;
}

.dropdown_menu a i {
  color: #fff;
  background: linear-gradient(180deg, #233076 0%, #19619c 100%);
  padding: 8px 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
}
.dropdown_menu span {
  margin: 0 10px;
}
.d-flex .background2 {
  background: #ffffff;
  padding-bottom: inherit;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1045;
}

li.nav-item.dropdown.text_down {
  margin-bottom: 17px;
}

li.nav-item.activities-b {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.activation-notification {
  position: relative;
}
.activation-notification span.badge {
  position: absolute;
  top: -10px;
  z-index: 3;
  background: #0d6efd;
  right: -8px;
  border-radius: 50%;
}
.activation-notification a{
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(25, 97, 156, 0.2);
  padding: 15px;
  color: #0d6efd;
}
