td.description {
    text-align: justify;
}
/* 
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    height: 120px;
} */

select.select_one.industrylist {
    height: 51px;
}

.industry_table{
    margin-top: 70px;
}

.heading__container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.addNewBtn {
    margin-bottom: 10px;
}

.duplicateBtn{
    padding: 0px;
    height: 39px;
    width: 140px;
    font-size: 13px;
}


.actions_ico svg {
    height: 20px;
    fill: #05b49d;
    cursor: pointer;
    width: 20px ;
}

.actions_ico {
    height: 20px;
    fill: #05b49d;
    cursor: pointer;
    width: 20px ;
}

.actions_cont{
    display: flex;
    justify-content: space-around;
}