.omne {
    width: 100%;
}
.impa {
    justify-content: center !important;
}

.sdgSaveBtn {
    display: flex;
    justify-content: flex-end;
}