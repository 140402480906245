.ansField {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-top: 15px;
}

.ansField:focus {
    outline: none !important;
    background-color: #fff;
    border-color: #86b7fe;  
    border: 1px solid #05b49d !important
}

.modal_box.modal.show .modal-dialog {
    transform: translateY(30px);
    width: 100%;
    max-width: 800px;
  }
  
  .modal_cont{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-height: 90%;
  }