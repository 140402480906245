.accordion-body {
    padding: 0px;
}

.accordionSpc {
    padding: 0px !important;
}

.scopeTable {
    background: #f6f7fa;
    width: 100%;
}
.tableHeadCell {
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 16.52px;
    text-align: left;
    /* background: #233076; */
    /* color: #fff; */
    padding: 0px;
    letter-spacing: 0.02em;
    color: #4f5367;
    padding: 20px;
}
.tableHr {
    color: #c9c8c8;
    margin: 0px !important;
}

.tableBodyCell{
    border-radius: 2px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 20px;
}

.accordianItem{
    margin-bottom: 20px;
}

.accHeader button{
    padding: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    border: 1px solid #efefef !important;
    background: #eaebed !important;
}


