td.description {
    text-align: justify;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    height: 120px;
}

select.select_one.industrylist {
    height: 51px;
}

.industry_table{
    margin-top: 70px;
}