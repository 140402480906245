.forms .form-group .form-control {
  height: 52px;
  margin-top: 10px;
  background: #f8fbff;
  border: 1px solid rgba(185, 185, 185, 0.5);
}
.heading h4 {
  color: #2b377b;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
}

table td{
  font-size: 16px !important;
}

.zoho_view_button_next {
  width: 100px;
  height: 35px;
  border: 2px solid #4f5367;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4a4e61;
  text-decoration: none;
  background: #fff;
}

.zoho_view_button_next:hover {
  background: linear-gradient(94.41deg, #233076 -47.54%, #2EB9E0 114.39%);
  color: white;
  border: none;
}

.zoho_delete_button_next {
  width: 100px;
  height: 35px;
  border: 2px solid #4f5367;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4a4e61;
  text-decoration: none;
  background: #fff;
}

.zoho_delete_button_next:hover {
  background: #c20c0c;
  color: white;
  border-color: #c20c0c;
}

a.select.active {
  background: #fff;
  padding: 20px 50px;
  border-radius: 50px;
  border: 1px solid #05b49d;
  color: #05b49d;
  text-decoration: none;
}

.heading hr {
  border-bottom: 1px solid #d9dff8;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.upload_image {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
img.file-upload-image {
  position: relative;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  background-position: center center;
  border-radius: 50%;
}
/* .upload_image input[type="file"] {
  position: absolute;
  top: 3px;
  bottom: 0;
  right: 114px;
  opacity: 0;
  cursor: pointer;
  width: 250px;
  height: 250px;
} */
.upload_image input[type="file"] {
  position: absolute;
  top: -8px;
  bottom: 0;
  right: 138px;
  opacity: 0;
  cursor: pointer;
  width: 200px;
  height: 200px;
}
.romve_underline {
  border-bottom: none !important;
}
.heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* hr.line {
  margin-bottom: 30px;
} */
.heading i {
  color: #6a6f89;
  cursor: pointer;
}
.heading .select_subscription {
  width: 100%;
  min-width: 250px;
  -webkit-appearance: menulist;
}
.card-1 {
  width: 100%;
  box-shadow: 0 0 14px 7px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 7px;
  margin: 10px auto;
  min-height: 250px;
}

.subbutton {
  margin-top: -25px;
  justify-content: center;
  margin-left: 0;
}

.card-1 ul li {
  line-height: 40px;
  list-style-type: none;
}
.card-1 ul {
  padding-left: 0;
}
.card-1 h4 {
  color: #19619c;
  font-weight: 700;
  font-size: 42px;
  text-align: center;
}
.card-1 h4 span {
  font-weight: normal;
  font-size: 22px;
}
.card-1 h6 {
  color: #4f5367;
  text-align: center;
}
.data_card-1 {
  margin: 0 auto;
  width: 100%;
  padding: 0 50px;
  height: 120px;
  overflow-y: scroll;
  overflow-y: scroll;
  margin-bottom: 5px;
  background: #eee;
}
span.tag {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 700;
  color: #4f5367;
}
.custom-switch {
  width: 100%;
  text-align: center;
}
.sub-heading p {
  font-size: 14px;
  margin-bottom: 30px;
}
.btn-b a {
  font-size: 18px;
  border-bottom: 1px solid #0d6efd;
  border-radius: 0;
  color: #233076;
  font-weight: 700;
}
.img_button {
  width: 100%;
  display: flex;
  align-items: center;
}
.img_button .btn-d {
  justify-content: flex-start;
  margin-left: 60px;
}
span.cards-de {
  margin-left: 20px;
}
.cards-de button {
  width: 100%;
  max-width: 320px;
  margin-bottom: 10px;
}
.heading_color {
  background: #ebefff;
}
.table_f table {
  border: 1px solid #ebefff;
}
.table_f table tbody tr td {
  background: #fff;
  height: 66px;
  vertical-align: center;
  padding-top: 10px;
}
.date-picker {
  background: #f8fbff;
  height: 52px;
  margin-top: 11px;
}
.pagination_billing ul {
  text-decoration: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
}
.pagination_billing ul li {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: space-around;
}
.center {
  display: flex;
  justify-content: center;
  width: 100%;
}
.index {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disableddd:disabled {
  opacity: 0.65 !important;
}
.data_card-1::-webkit-scrollbar {
  display: none;
} 

.data_card-1 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* .left{
  background-color: #c20c0c;
} */