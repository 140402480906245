.main_wrapper .enviornment_consideration li button {
    border: none !important;
    color: #000;
    width: 100%;
}
.enviornment_consideration{
    border-bottom: none !important;
}
.color_div .enviornment_consideration li .nav-link.active {
    border: none !important;
    color: #233076 !important;
    border-bottom: 1px solid #233076 !important;
    border-radius: 0;
    text-align: center;
    margin: 0 auto;
}
