.drag-text h3 {
  font-size: 12px;
  text-align: center;
  padding: 10px 0 0;
  color: #4f5367;
  line-height: 19px;
  margin-bottom: 0;
}
.question_two.text-center {
  width: 100%;
  margin: 0 auto;
}
.issue_it {
  width: 100% !important;
  max-width: 400px !important;
  margin: 0 auto !important;
}
.table_f .table tr th {
  padding: 20px;
}
.admin-risk-table .table tr th{
  padding-left: 10px;
}

.admin-risk-table .table tr th{
  padding-left: 10px;
}

.admin-risk-table .center.red{
  justify-content: flex-start;
}
.admin-risk-table .center.green{
  justify-content: flex-start;
}
.admin-risk-table .center.bold{
  justify-content: flex-start;
}

.table_f table tbody tr td {
  background: none;
  height: 66px;
  vertical-align: center;
  padding: 10px;
  vertical-align: middle;
  padding: 20px;
}
.manage-detail .global_link .page_save.page_width {
  min-width: 100px;
}
.manage-detail td {
  word-break: break-all;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #F5F7FF;
  color: var(--bs-table-striped-color);
}

.upload-document {
  position: relative;
  cursor: pointer;
}

.upload-document .upload_files {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  opacity: 0;
  top: 0;
  left: 46px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  right: 0;
}

.download_files_supplier{
  left: 0 !important;
  cursor: pointer !important;
}

.supply-addd {
  text-decoration: none;
}

.supply-add{
  color: #233076;
  opacity: 0.7;
}

.variants {
  display: flex;
  margin: 16px;
}

.variants > div {
  margin-right: 5px;
}

.variants > div:last-of-type {
  margin-right: 0;
}

.file {
  position: relative;
  display: flex;
}

.file > input[type='file'] {
  display: none
}

.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: #1c5593;
  border-color: #1c5593;
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

a.outputFile {
  border: 1px solid #05b49d;
  border-radius: 5px;
  padding: 15px 40px;
  text-decoration: none;
  margin-left: 20px;
  font-size: 15px;
  color: #038977;
}

a.outputFile:hover {
  background-color: #05b49d1a;
  border: 1px solid #05b49d;
  border-radius: 5px;
  padding: 15px 40px;
  text-decoration: none;
  margin-left: 20px;
  font-size: 15px;
  color: #038977;
}

h5.feedbackTitle {
  font-size: 25px;
  font-weight: 600;
  color: #1e498a;
}

.saveAndTitleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#savbtnMain5{
  margin-right: 45px;
}

.supplierInp {
  padding: 15px !important;
}

.supplierInpCont {
  margin: 20px;
}

.mainTitleSupplier {
  text-align: center;
}

.supplierBtnConts {
  justify-content: flex-end;
  gap: 30px;
}

.cnlSupplierBtn {


}

.svSupplierBtn {
  
}

.supplierFormLable {
  display: flex;
}

.actionsCont {
  display: flex;
  justify-content: space-around;
}