.view-button {
  border: 1px solid #eee !important;
  border-radius: 0 20px 20px 0 !important;
}
.view-button i {
  margin: 0 10px;
  color: #233076;
}
.view-button i span {
  font-family: proxima, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.view-button:hover {
  background: linear-gradient(
    91.53deg,
    #233076 13.86%,
    #41c7ed 112.43%
  ) !important;
}
.strp_progess{
  padding: 0;
}

.view-button i:hover {
  color: #fff;
}
.feedback2.ui.modal {
  width: 900px;
  margin: 0;
  height: 260px;
  margin: 260px 25%;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5);
}


i.fas.fa-check.green, i.fas.fa-times.red {
  cursor: pointer;
}

.feedback2.ui.modal {
  width: 900px;
  margin: 0;
  height: 350px;
  margin: 260px 25%;
}

.addpolicyModal.ui.modal {
  width: 900px;
  margin: 0;
  height: 315px;
  margin: 260px 25%;
}
.feedback3.ui.modal {
  width: 900px;
  margin: 0;
  height: 655px !important;
  margin: 140px 25%;
}

.commentsBox {
  padding: 20px;
  height: 28vh;
  overflow-y: scroll;
  border: 1px solid #d9d1d1;
  border-radius: 5px;
}

.sender {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
}

.senderMessage {
  width: 49%;
  background: #00800036;
  font-size: 15px !important;
  padding: 5px 15px;
  border-radius: 10px 0px 10px 0px;
}

.receiverMessage {
  width: 49%;
  background: #00800036;
  font-size: 15px !important;
  padding: 5px 15px;
  border-radius: 0px 10px 0px 10px;
}

span.commentDate {
  font-weight: 700;
  font-size: 11px;
  margin-top: 10px;
  display: block;
}

.receiver {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}


.savbtnCont {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

 #savbtnMain {
  width: 10% !important;
}

#savbtnMain2 {
  width: 15% !important;
}

.headerCont {
  display: flex;
  justify-content: space-between;
}

.mdInpPolicy {
  height: 55px;
}

.policyBtnCont {
  display: flex;
  justify-content: flex-end;
}